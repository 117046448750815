<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <h3 class="inBlock">预警订单</h3>
    </div>
    <div class="box">
      <div class="condition">
        <div class="type">
          <p>
            <span>门店名称:</span>
            <el-input
              v-model="form.store_name"
              placeholder="请输入订单编号"
              size="small"
              clearable
            >
            </el-input>
          </p>
          <p>
            <span>订单编号:</span>
            <el-input
              placeholder="请输入订单编号"
              size="small"
              v-model="form.order_no"
              clearable
            >
            </el-input>
          </p>
          <p>
            <span>门店ID:</span>
            <el-input
              placeholder="请输入门店ID"
              size="small"
              v-model="form.store_id"
              clearable
            >
            </el-input>
          </p>
        </div>
        <div class="time" style="margin-bottom: 20px;">
          <span class="orderTime">下单时间: </span>
          <el-date-picker
            v-model="TimeValue"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="left"
            size="small"
          >
          </el-date-picker>
           <span class="orderTime">申请时间: </span>
          <el-date-picker
            v-model="startTime"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="left"
            size="small"
          >
          </el-date-picker>
        </div>
        <el-button type="success" size="small" plain @click="exportShop"
          >导出列表</el-button
        >
        <el-button type="primary" plain size="small" @click="search"
          >点击筛选</el-button
        >
      </div>
      <el-table style="width: 95%" :data="list" border>
        <el-table-column type="expand">
          <div slot-scope="scope" class="orderbox">
            <div class="orderGoods cleafix">
              <h4>订单商品</h4>
              <div
                class="goodsDetail"
                v-for="item in scope.row.order.order_detail"
                :key="item.id"
              >
                <div class="img">
                  <img style="width: 50px;" :src="imgurl + item.pic" alt="" />
                </div>
                <div class="data">
                  <p class="sheng">{{ item.goods_name }}</p>
                  <p class="sheng">
                    规格:
                    <span v-if="item.attr != ''" style="margin-right: 20px">{{
                      item.attr
                    }}</span>
                    <span v-else style="margin-right: 20px">默认</span>
                    数量:<span>{{ item.num }}</span>
                  </p>
                  <p class="sheng">
                    小计: <span>{{ item.total_price }}</span
                    >元
                  </p>
                </div>
              </div>
            </div>
            <div class="order">
              <p>
                用户:<span>{{ scope.row.store_user.username }}</span>
              </p>
              <p>
                订单编号:<span>{{ scope.row.order.order_no }}</span>
              </p>
              <p>
                实付金额:<span>{{ scope.row.order.pay_price }}</span
                >元
              </p>
              <p>
                总金额:<span>{{ scope.row.order.total_price }}</span
                >元
              </p>
            </div>
          </div>
        </el-table-column>
        <el-table-column label="ID" prop="id"> </el-table-column>
        <el-table-column label="门店ID" prop="store_id"> </el-table-column>
        <!-- <el-table-column label="退货单号" prop="order_refund_no">
        </el-table-column> -->
        <el-table-column label="所属门店">
          <div slot-scope="scope">
            {{ scope.row.store.name }}
          </div>
        </el-table-column>
        <el-table-column label="申请时间" prop="addtime">
          <div slot-scope="scope">
            {{ scope.row.addtime | datefilter }}
          </div>
        </el-table-column>
        <el-table-column label="退货金额" prop="refund_price">
        </el-table-column>
        <el-table-column label="售后类型" width="200">
          <el-tag size="mini" type="warning" effect="dark">退货</el-tag>
        </el-table-column>
        <!-- <el-table-column label="申请理由" prop="desc"></el-table-column> -->
        <el-table-column label="商家操作时间">
          <div slot-scope="scope">
            <span v-if="scope.row.response_time != 0">{{
              scope.row.response_time | datefilter
            }}</span>
            <span v-else>暂未处理</span>
          </div>
        </el-table-column>
        <el-table-column label="进度" prop="status">
          <div slot-scope="scope">
            <el-tag
              v-if="scope.row.status == 1"
              size="mini"
              type="success"
              effect="dark"
              >已处理</el-tag
            >
            <el-tag
              v-else-if="scope.row.status == 0"
              size="mini"
              type="info"
              effect="dark"
              >未处理</el-tag
            >
            <el-tag v-else size="mini" type="danger" effect="dark"
              >已拒绝</el-tag
            >
          </div>
        </el-table-column>
        <el-table-column label="操作">
          <div class="caozuo" slot-scope="scope">
            <div
              class="btnBJ shou"
              style="margin-right: 8px"
              @click="comfirm(scope.row.id)"
            >
              同意
            </div>
            <div
              class="btnBJ shou"
              style="background-color: #1dbf9e; margin-right: 10px"
              @click="cancle(scope.row.id)"
            >
              拒绝
            </div>
          </div>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="CurrentChange"
        :current-page="form.page"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog title="导出范围" :visible.sync="exportShow" width="25%">
      <div>
        <p style="padding: 0 0 20px 0; font-size: 12px; color: #e6a23c">
          将导出满足筛选条件的门店!
        </p>
        <el-radio v-model="exportType" :label="1">当前页</el-radio>
        <el-radio v-model="exportType" :label="2">导出所有</el-radio>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="exportShows">取 消</el-button>
        <el-button type="primary" @click="exportOK">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import { warningOrder, applyOrderRefund } from "@/api/api.js";
import { getSystemsettings } from "../../api/getSystemsetting.js";
import { filenameFormat } from "@/utils/index";
export default {
  components: {},
  name: "orderAfter",
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      exportShow: false,
      exportType: 1,
      imgurl: "",
      form: {
        order_no: null,
        start_time: null,
        end_time: null,
        order_refund_no: null,
        apply_start_time: null,
        apply_end_time: null,
        page: 1,
        store_name: "",
        store_id: "",
      },
      total: 1,
      TimeValue: null,
      startTime: null,
      list: [],
    };
  },
  methods: {
    CurrentChange(page) {
      this.form.page = page;
      this.getlist()
    },
    async getlist() {
      if (!this.form.export) {
        const { data } = await warningOrder(this.form);
        if (data.code != 200) return this.$message.error(data.data);
        this.list = data.data.warningorder_refund_list.data;
        this.total = data.data.warningorder_refund_list.total;
        console.log(this.list);
        console.log(data);
      } else {
        let { data, types, request } = await warningOrder(this.form, "blob");
        let blob = new Blob([data], { type: "application/vnd.ms-excel" }); //type是文件类，详情可以参阅blob文件类型
        console.log(request.getAllResponseHeaders());
        // decodeURI(request.getResponseHeader("content-disposition").split('=')[1])
        let filename = decodeURI(
          request.getResponseHeader("content-disposition")
        );
        let objectUrl = URL.createObjectURL(blob); //生成下载链接
        let a = document.createElement("a"); //创建a标签用于文件下载
        a.href = objectUrl; //赋值下载路径
        a.download = filenameFormat(
          request.getResponseHeader("content-disposition")
        ); //下载的文件名称（非必填）
        document.body.appendChild(a); //插入DOM树
        a.click(); //点击a标签触发
        document.body.removeChild(a); //删除a标签
        // this.form.export=''
      }
    },

    async comfirm(id) {
      const { data } = await applyOrderRefund({
        id: id,
        status: 1,
      });
      if (data.code != 200) return this.$message.error(data.data);
      this.$message.success("订单处理成功");
      console.log(data);
    },

    async cancle(id) {
      const { data } = await applyOrderRefund({
        id: id,
        status: 2,
      });
      if (data.code != 200) return this.$message.error(data.data);
      this.$message.success("订单处理成功");
      console.log(data);
    },
    // 导出订单
    exportShop() {
      this.exportShow = true;
    },
    // 取消导出
    exportShows() {
      this.exportShow = false;
    },
    // 确定导出
    exportOK() {
      this.exportShow = false;
      this.form.export = "export";
      this.form.export_type = this.exportType;
      this.getlist();
      delete this.form.export;
      delete this.form.export_type;
    },
    search() {
      if (this.TimeValue != null) {
        this.form.start_time = this.$timeTo.time4(this.TimeValue[0]);
        this.form.end_time = this.$timeTo.time4(this.TimeValue[1]);
      } else {
        this.form.start_time = "";
        this.form.end_time = "";
      }
      
      if (this.startTime != null) {
        this.form.apply_start_time = this.$timeTo.time4(this.startTime[0]);
        this.form.apply_end_time = this.$timeTo.time4(this.startTime[1]);
      } else {
        this.form.apply_start_time = "";
        this.form.apply_end_time = "";
      }
      this.form.page = 1;
      this.getlist();
    },
    setOrderType(e) {
      this.orderItem = e;
    },
  },
  created() {
    this.getlist();
    getSystemsettings.then((res) => {
      this.imgurl = res;
    });
  },
};
</script>

<style scoped lang='less'>
.box {
  /deep/.orderbox {
    border: 1px solid #999;
    display: flex;
    margin: 5px;
    align-items: center;
    .order {
      padding: 10px 20px 5px 20px;
      p {
        margin-right: 10px;
        font-size: 16px;
        margin-bottom: 5px;
        span {
          margin-left: 5px;
        }
      }
    }
    /deep/.orderGoods {
      border-right: 1px solid #999;
      padding: 10px 20px;
      width: 600px;
      h4 {
        font-weight: normal;
        font-size: 16px;
        margin-bottom: 2px;
      }
      .goodsDetail {
        border: 1px solid #333;
        &:hover {
          border: 1px solid #aaa;
        }
        width: 500px;
        margin-right: 10px;
        border: 1px solid #eee;
        padding: 15px 10px;
        margin-bottom: 5px;
        display: flex;
        .img {
          width: 50px;
          height: 50px;
          background-color: #e9ebec;
          margin-right: 10px;
          img {
            width: 100%;
          }
        }
        .data {
          p {
            font-size: 12px;
            &:nth-child(2) {
              margin: 5px 0;
            }
            span {
              color: #db5376;
            }
          }
        }
      }
    }
  }

  .orderstatus {
    .el-tag {
      margin: 0 10px 2px 0;
    }
  }
  .condition {
    height: 150px;
    background: #f1f3f4;
    padding: 10px;
    margin: 20px 0;
    .orderTime {
      display: inline-block;
      font-size: 14px;
    }
    .type {
      display: flex;
      padding: 10px 0;
      .el-input {
        width: 260px;
      }
      p {
        margin-right: 10px;
        font-size: 14px;
        span {
          display: inline-block;
        }
      }
    }
  }
}
.check {
  display: inline-block;
  margin-left: 30px;
  font-size: 14px;
  span {
    margin-right: 10px;
    display: inline-block;
  }
}
/deep/.warning-row {
  background: oldlace;
}

/deep/.success-row {
  background: #f0f9eb;
}
/deep/.el-table__expanded-cell[class*="cell"] {
  padding: 0;
}
</style>